import { match } from "@pomebile/shared/tagged-union"
import {
  AppEvent,
  AppScreen,
  AppState,
  calculateRecipientVerificationScreen,
} from "./recipientVerificationModel"
import {
  AppApiContext,
  queryUniqueLinkInfo,
  RecipientVerificationVeriffSdkResponse,
  submitMicroDepositAmount,
} from "./api/webRoutes"
import { GeneralErrorScreen } from "./screens/GeneralError"
import { RecipientVerificationIntro } from "./screens/RecipientVerificationIntro"
import {
  createAuthContext,
  createLoggingContext,
  InitialData,
  ShellState,
} from "./sharedShellLogic"
import { DEBUG_RESTORE_KEY, DEBUG_STORAGE_KEY } from "./components/DevTools"
import { anonymousIdPromise } from "./utils/segment"
import { RecipientVerificationInvalidLink } from "./screens/RecipientVerificationInvalidLink"
import { RecipientVerificationTooManyAttempts } from "./screens/RecipientVerificationTooManyAttempts"
import { RecipientVerificationSuccess } from "./screens/RecipientVerificationSuccess"
import { RecipientVerificationConfirmDeposit } from "./screens/RecipientVerificationConfirmDeposit"
import { FetchingUniqueLinkInfo } from "./screens/RecipientVerificationFetchingUniqueLinkInfo"
import { TimeLimitErrorScreen } from "./screens/TimeLimitError"
import { RecipientVerificationRejectedScreen } from "./screens/RecipientVerificationRejected"
import {
  RecipientVerificationFetchingVeriffDecision,
  RecipientVerificationVeriffDecision,
} from "./screens/RecipientVerificationFetchingVeriffDecision"
import { RecipientVerificationRetryIdentityVerification } from "./screens/RecipientVerificationRetryIdentityVerification"
import { createVeriffFrame, MESSAGES } from "@veriff/incontext-sdk"
import { RecipientVerificationConfirmBankAccount } from "./screens/RecipientVerificationConfirmBankAccount"

const MAX_TIME_LIMIT = 30000 // 30 seconds, max time limit we will continue to re-poll
const RETRY_INTERVAL = 5000 // 5 seconds, how often to re-poll

const pollVeriffDecision = async (
  recipientVerificationAttemptId: string,
  apiCx: AppApiContext,
  retryCount = 0,
): Promise<RecipientVerificationVeriffDecision> => {
  const decision = await queryUniqueLinkInfo(recipientVerificationAttemptId, apiCx)

  if (decision.tag === "error") {
    return { tag: "unexpectedError" }
  }

  switch (decision.info.veriff.tag) {
    case "approved":
    case "declined":
      return { tag: decision.info.veriff.tag }

    case "resubmit":
      return { tag: "resubmit", url: decision.info.veriff.url }

    default: {
      if (MAX_TIME_LIMIT > retryCount * RETRY_INTERVAL) {
        await new Promise<void>((resolve) => {
          setTimeout(() => {
            resolve()
          }, RETRY_INTERVAL)
        })

        return pollVeriffDecision(recipientVerificationAttemptId, apiCx, retryCount + 1)
      }

      return { tag: "timeoutError" }
    }
  }
}

export type Progression = "none"

export const calculateProgression = (): Progression => "none"

export const renderScreen = (
  screen: AppScreen,
  send: (ev: AppEvent) => void,
  apiCx: AppApiContext,
): JSX.Element =>
  match(
    screen,
    {
      FetchingUniqueLinkInfo: ({ recipientVerificationAttemptId }) => (
        <FetchingUniqueLinkInfo
          api={{
            fetchUniqueLinkInfo: () => queryUniqueLinkInfo(recipientVerificationAttemptId, apiCx),
          }}
          onDone={(resp) => {
            switch (resp.tag) {
              case "validUrl":
                send(AppEvent.FetchedUniqueLinkInfo({ uniqueLinkInfo: resp.info }))
                break

              case "error":
                send(AppEvent.UsedInvalidLink())
                break

              case "unexpectedError":
                send(
                  AppEvent.EncounteredGeneralError({
                    errorType: "recipientVerificationUniqueLinkUnexpectedError",
                  }),
                )
                break
            }
          }}
        />
      ),
      VerifyIdentity: ({ senderName, transferAmount, transferExpiration, veriff }) => (
        <RecipientVerificationIntro
          recipientName={senderName}
          transferAmount={transferAmount}
          transferExpiration={transferExpiration}
          veriffUrl={veriff.tag === "veriffRequired" ? veriff.veriffUrl : undefined}
          api={{
            startVeriffSdk: (url) => {
              const veriffResultProm = new Promise<RecipientVerificationVeriffSdkResponse>(
                (resolve) => {
                  createVeriffFrame({
                    url,
                    onEvent: (ev) => {
                      switch (ev) {
                        case MESSAGES.FINISHED:
                          resolve({ type: "finished" })
                          break
                        case MESSAGES.RELOAD_REQUEST:
                          resolve({ type: "reload_required" })
                          break
                        case MESSAGES.CANCELED:
                          resolve({ type: "user_cancelled" })
                      }
                    },
                    onReload: () => {
                      resolve({ type: "reload_required" })
                    },
                  })
                },
              )
              return veriffResultProm
            },
          }}
          onDone={() => {
            send(AppEvent.SubmittedIdentityVerification())
          }}
        />
      ),
      ConfirmBankAccount: ({ recipientBankLast4, recipientBankPayer }) => (
        <RecipientVerificationConfirmBankAccount
          bankPayer={recipientBankPayer}
          last4={recipientBankLast4}
          onDone={() => send(AppEvent.ConfirmedBankAccount())}
        />
      ),

      ConfirmDeposit: ({
        recipientVerificationAttemptId,
        recipientBankLast4,
        recipientBankPayer,
      }) => (
        <RecipientVerificationConfirmDeposit
          bankPayer={recipientBankPayer}
          last4={recipientBankLast4}
          api={{
            verifyDepositAmount: (depositAmount) =>
              submitMicroDepositAmount(recipientVerificationAttemptId, depositAmount, apiCx),
          }}
          onDone={(outcome) => {
            const { tag } = outcome

            switch (tag) {
              case "success":
                send(AppEvent.ConfirmedDepositAmount())
                break

              case "tooManyAttempts":
                send(AppEvent.ExceededAttemptsLimit())
                break

              case "unexpectedError":
                send(
                  AppEvent.EncounteredGeneralError({
                    errorType: "recipientVerificationConfirmDepositUnexpectedError",
                  }),
                )
            }
          }}
        />
      ),
      FetchingVeriffDecision: ({ recipientVerificationAttemptId }) => (
        <RecipientVerificationFetchingVeriffDecision
          api={{
            queryVeriffDecision: () => pollVeriffDecision(recipientVerificationAttemptId, apiCx),
          }}
          onDone={(outcome) => {
            const { tag } = outcome

            switch (tag) {
              case "success":
                send(AppEvent.CompletedIdentityVerification())
                break

              case "failed":
                send(AppEvent.FailedRecipientVerification())
                break

              case "resubmissionRequired":
                send(AppEvent.RequiredIdentityVerificationResubmission({ veriffUrl: outcome.url }))
                break

              case "timeoutError":
                send(AppEvent.RequestTimedOut())
                break

              case "unexpectedError":
                send(
                  AppEvent.EncounteredGeneralError({
                    errorType: "recipientVerificationFetchingVeriffUnexpectedError",
                  }),
                )
                break
            }
          }}
        />
      ),
      RetryIdentityVerification: ({ veriffUrl }) => (
        <RecipientVerificationRetryIdentityVerification
          veriffUrl={veriffUrl}
          api={{
            startVeriffSdk: (url) => {
              const veriffResultProm = new Promise<RecipientVerificationVeriffSdkResponse>(
                (resolve) => {
                  createVeriffFrame({
                    url,
                    onEvent: (ev) => {
                      switch (ev) {
                        case MESSAGES.FINISHED:
                          resolve({ type: "finished" })
                          break
                        case MESSAGES.RELOAD_REQUEST:
                          resolve({ type: "reload_required" })
                          break
                        case MESSAGES.CANCELED:
                          resolve({ type: "user_cancelled" })
                      }
                    },
                    onReload: () => {
                      resolve({ type: "reload_required" })
                    },
                  })
                },
              )
              return veriffResultProm
            },
          }}
          onDone={() => {
            send(AppEvent.SubmittedIdentityVerification())
          }}
        />
      ),
      Success: ({ recipientBankLast4, recipientBankPayer }) => (
        <RecipientVerificationSuccess bankPayer={recipientBankPayer} last4={recipientBankLast4} />
      ),
      Rejected: () => <RecipientVerificationRejectedScreen />,
      InvalidLink: () => <RecipientVerificationInvalidLink />,
      TooManyAttempts: () => <RecipientVerificationTooManyAttempts />,
      TimeLimitError: () => <TimeLimitErrorScreen />,
      GeneralError: () => <GeneralErrorScreen />,
    },
    // There shouldn't ever be a case here, but just in case...
    (_) => <GeneralErrorScreen />,
  )

export function initRecipientVerification(
  apiCx: AppApiContext,
  isDevToolsEnabled: boolean,
): InitialData<AppState, AppScreen> {
  const { recipientVerificationAttemptId } = apiCx

  const logger = createLoggingContext()

  if (isDevToolsEnabled) {
    const restore = localStorage.getItem(DEBUG_RESTORE_KEY) === "yes"
    localStorage.removeItem(DEBUG_RESTORE_KEY)

    if (restore) {
      const serialized = localStorage.getItem(DEBUG_STORAGE_KEY)
      if (serialized) {
        const { state, apiCx }: { state: AppState; apiCx: AppApiContext } = JSON.parse(serialized)

        return {
          initialState: { state, screens: [calculateRecipientVerificationScreen(state)] },
          apiCx,
          authCx: createAuthContext(apiCx),
          logging: logger,
        }
      }
    }
  }

  const initialState: ShellState<AppState, AppScreen> = recipientVerificationAttemptId
    ? {
        state: AppState.FetchingInfo({ recipientVerificationAttemptId }),
        screens: [AppScreen.FetchingUniqueLinkInfo({ recipientVerificationAttemptId })],
      }
    : {
        state: AppState.InvalidLinkError(),
        screens: [AppScreen.InvalidLink()],
      }

  anonymousIdPromise?.then((id) => {
    apiCx.anonymousId = id
  })

  return {
    initialState,
    apiCx,
    authCx: createAuthContext(apiCx),
    logging: logger,
  }
}
